@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.gallery-item {

    margin: 0 0 rem(30) 0 !important;
    display: block !important;

    &__img {
        display: none !important;
    }

}