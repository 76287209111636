@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.content-card {

    background-color: transparent !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding: 0 !important;
    margin: 0 0 rem(20) 0 !important;
    border-radius: 0 !important;
    
    a {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        padding: 0 !important;
    }

    &__icon {
        display: none !important;
    }

}