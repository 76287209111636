@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.card-group {

    width: 100% !important;
    padding: rem($print-spacing) 0 !important;
    max-width: 100% !important;
    
    &__inner,
    &__wrapper {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
        display: block !important;
        background-color: transparent !important;
    }


    &__item {
        width: 100% !important;
        padding: 0 !important;
    }

}