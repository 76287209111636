@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.figure-block {

    width: 100%;
    display: block !important;
    margin: 0 0 rem(30) 0 !important;

    &__value {
        color: $black !important;
        margin: 0 0 rem(10) 0 !important;
    }

    &__content {
        color: $black !important;
        max-width: 100% !important;
    }

}
