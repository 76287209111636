@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.footer {

    padding: rem($print-spacing) 0 !important;
    
    &__copyright,
    &__links ul li a {
        color: $black !important;
    }

    &__shape,
    &__logos {
        display: none !important;
    }

    &__lower,
    &__wrapper,
    &__inner {
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
        text-align: left !important;
        background-color: transparent !important;
    }

}