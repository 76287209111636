@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.card-grid {

    padding: 0 !important;
    margin: 0 !important;

    &__inner {
        padding: 0 !important;
        max-width: 100% !important;
    }

    &__item {
        width: 100%;
        margin: 0 0 rem(25) 0 !important;
    }

}