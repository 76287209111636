@import "./imports/variables";

@media print {

    body {
        font-family: $latin-body-font !important;
        font-weight: $latin-body-font-weight !important;
        color: $black !important;
        filter: Gray();
        filter: url('#grayscale'); 
        -webkit-filter: grayscale(100%); 
        filter: grayscale(100%);

        * {
            color: $black !important;
        }

        iframe,
        form,
        .arrow-btn,
        .social,
        img,
        svg,
        .nav-accessibility,
        .side-bar,
        .header,
        .btn {
            display: none !important;
        }

        .animated,
        .is-visible {
            * {
                opacity: 1 !important;
                visibility: visible !important;
                transform: translate(0, 0) !important;
                color: $black !important;
            }
        }

        .component-wrapper {
            
            width: 100% !important;
            max-width: 100% !important;
            
            &__inner {
                padding: 0 !important;
                max-width: 100% !important;
                width: 100% !important;
            }
        }


        &.arabic-text {
            font-family: $arabic-body-font !important;
            font-weight: $arabic-body-font-weight !important;
            direction: rtl;
        }

        &.russian-text {
            font-family: $russian-body-font !important;
            font-weight: $russian-body-font-weight !important;
        }

        &.chinese-text {
            font-family: $chinese-body-font !important;
            font-weight: $chinese-body-font-weight !important;
        }


        .post-card-grid {
            width: 100% !important;
            display: block !important;
            padding: rem($print-spacing, 1) 0;
            
            &__inner {
                width: 100%;
                padding: 0 !important;
                max-width: 100% !important;
            }

            &__controls {
                display: none !important;
            }
        }

    }

    @import "../components/accordion/_print";
    @import "../components/background/_print";
    @import "../components/cardGrid/_print";
    @import "../components/cardGroup/_print";
    @import "../components/cardWysiwyg/_print";
    @import "../components/carousel/_print";
    @import "../components/carouselWrapper/_print";
    @import "../components/campaignCard/_print";
    @import "../components/columnCarousel/_print";
    @import "../components/contentBanner/_print";
    @import "../components/colourCard/_print";
    @import "../components/colourCardRow/_print";
    @import "../components/contentCard/_print";
    @import "../components/definitionItem/_print";
    @import "../components/downloadCard/_print";
    @import "../components/homeBanner/_print";
    @import "../components/eNewsForm/_print";
    @import "../components/featureContent/_print";
    @import "../components/featureContentIntro/_print";
    @import "../components/featureCtaRow/_print";
    @import "../components/featureImg/_print";
    @import "../components/featureLogoRow/_print";
    @import "../components/figureBlock/_print";
    @import "../components/footer/_print";
    @import "../components/galleryItem/_print";
    @import "../components/iconBlock/_print";
    @import "../components/iconBlockRow/_print";
    @import "../components/instagram/_print";
    @import "../components/imgGroup/_print";
    @import "../components/imgContentCard/_print";
    @import "../components/imgTitleContentCard/_print";
    @import "../components/linkBlock/_print";
    @import "../components/linkBlockRow/_print";
    @import "../components/map/_print";
    @import "../components/postCardGrid/_print";
    @import "../components/postCardRow/_print";
    @import "../components/sectionIntro/_print";
    @import "../components/social/_print";
    @import "../components/sideBar/_print";
    @import "../components/staticEmbed/_print";
    @import "../components/termBlockRow/_print";
    @import "../components/toolbar/_print";
    @import "../components/updateCard/_print";
    @import "../components/youtubeVideo/_print";
    @import "../components/wysiwyg/_print";

}