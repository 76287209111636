@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.post-card-row {

    display: block !important;
    padding: rem($print-spacing) 0 0 0 !important;

    &__column,
    &__feature-col {
        display: block !important;
        width: 100% !important;
    }

    &__column {

        display: block !important;
        width: 100% !important;

        .update-card,
        .campaign-card {
            width: 100% !important;
            margin: 0 0 rem(30) 0 !important;
        }
    }

}