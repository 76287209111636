@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

a.link-block,
.link-block {

    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 0 rem(40) 0 !important;
    text-decoration: none !important;

    &[target="_blank"] {

        &:after {
            display: none;
        }

        span {
            
            &:after {
                display: none !important;
            }
        }

    }
    
    p {
        padding: 0 0 rem(15) 0 !important;
    }


}