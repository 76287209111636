@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.term-block-row {
    background-color: transparent !important;

    &__item {
        width: 100% !important;
        margin: 0 !important;
    }
}