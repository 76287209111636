@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.update-card {

    display: block !important;
    width: 100%;
    box-shadow: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;

    &__icon,
    &__img {
        display: none !important;
    }

    &__foot,
    &__lower {
        position: relative !important;
        border: none !important;
        background-color: transparent !important;
        padding: 0 !important;
        display: block !important;
    }

    h4,
    h5,
    p,
    &__content {
        color: $black !important;
    }

    &__tag {
        display: none !important;
    }


}