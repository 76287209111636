@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.wysiwyg {
    
    display: block !important;
    padding: 0 0 rem(30) 0 !important;
    width: 100% !important;
    max-width: 100% !important;

    &__inner {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
    }
    
}