@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.carousel-wrapper {

    display: block !important;

    &__container {
        padding: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    &__slide-row {
        transform: none !important;
        width: 100% !important;
        display: block !important;
    }

    &__page {
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        display: block !important;
    }

}