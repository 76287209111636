@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.download-card {
    background-color: transparent !important;
    width: 100% !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;

}