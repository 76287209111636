@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.campaign-card {

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0 !important;
    width: 100% !important;
    display: block !important;
    min-height: 0 !important;
    background-color: transparent !important;

    .h5,
    h4,
    p {
        color: $black !important;
        width: 100% !important;
        display: block !important;
        max-width: 100% !important;
    }

    &__img,
    &__icon {
        display: none !important;
    }

    &__content {
        width: 100% !important;
        margin: 0 !important;
        opacity: 1 !important;
        display: block !important;
        visibility: visible !important;
        color: $black !important;
        max-width: 100% !important;

        * {
            color: $black !important;
        }
    }

    &__lower {
        width: 100% !important;
        position: relative !important;
        padding: 0 !important;
        min-height: 0 !important;
        border: none !important;
        display: block !important;
        background-color: transparent !important;
    }

}