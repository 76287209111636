@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.carousel {

    padding: rem($print-spacing) 0 !important;

    &__lower {
        display: block !important;
    }

    &__controls {
        display: none !important;
    }

    &__container {
        max-width: 100% !important;
    }

}