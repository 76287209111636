@import "../../styles/imports/utils";

.img-content-card {

    padding: 0 0 rem($print-spacing) 0;
    display: block !important;
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    box-shadow: none !important;
    
    &__img {
        display: none !important;
    }

    &__content,
    &__inner {
        width: 100% !important;
        display: block !important;
        padding: 0 !important;
        max-width: 100% !important;
    }

    &__wrapper {
        background-color: transparent !important;
        box-shadow: none !important;
        -webkit-box-shadow: none;
    }

}