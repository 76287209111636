@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.img-title-content-card {

    padding: rem($print-spacing) 0 !important;

    a {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        display: block !important;
        width: 100% !important;
        height: auto !important;
    }

    &__img,
    &__icon {
        background-color: transparent !important;
        display: none !important;
    }

    &__content {
        
        padding: 0 !important;
        height: auto !important;
        max-height: 100% !important;
        border: none !important;
        display: block !important;

        p {
            display: block !important;
            padding: 0 !important;
            color: $black !important;
        }
    }

    
    h4 {
        padding: 0 !important;
        color: $black !important;
    }

}