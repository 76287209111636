@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.feature-logo-row {

    padding: 0 !important;
    
    &__wrapper {
        background-color: transparent !important;
    }

    &__inner {
        max-width: 100% !important;
        padding: 0 !important;
    }

    &__logo {
        display: none !important;
    }

}