@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.colour-card-row {
    margin: rem($print-spacing) 0 !important;
    padding: 0 !important;

    &__inner {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
    }

    &__item {
        width: 100% !important;
    }

}