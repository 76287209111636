@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.accordion {

    margin: 0 0 rem(30) 0 !important;

    &__title {
        padding: 0 !important;
        box-shadow: none !important;
        -webkit-box-shadow: 0 !important;
        border: none !important;
        background-color: transparent !important;
        color: $black !important;

        svg {
            display: none !important;
        }
    }

    &__content {
        background-color: transparent !important;
        padding: 0 !important;
        color: $black !important;
        display: block !important;

        * {
            color: $black !important;
        }
    }


}