@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.colour-card {
    
    width: 100% !important;
    margin: 0 0 rem($print-spacing) 0 !important;
    padding: 0 !important;

    &__icon {
        display: none !important;
    }

    a {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        padding: 0 !important;
        display: block !important;
        width: 100% !important;
        min-height: 0 !important;
    }

}