@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.feature-cta-row {
    
    width: 100% !important;
    padding: rem($print-spacing) 0 !important;

    &__btn {
        display: none !important;
    }

    h3 {
        color: $black !important;
    }

    &__col {
        max-width: 100% !important;
    }


}