@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.feature-content-intro {

    width: 100% !important;
    margin: 0;
    border: none !important;
    padding: rem($print-spacing) 0 !important;
    max-width: 100% !important;

    &--landing {
        max-width: 100% !important;
    }

}
