@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.definition-item {

    width: 100% !important;
    padding: 0 !important;
    margin: 0 0 rem(30) 0 !important;
    display: block !important;

    &__inner {
        width: 100% !important;
        padding: 0 !important;
    }

    button {
        color: $black !important;
    }

    h3 {
        color: $black !important;
        padding: 0 0 rem(20) 0 !important;
    }

    &__content {
        color: $black !important;
        display: block !important;

        * { 
            color: $black !important;
        }
    }
}