@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.enews-form {

    width: 100% !important;
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;

    &__intro,
    &__disclaimer,
    &__inner {
        padding: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
        background-color: transparent !important;
    }

}