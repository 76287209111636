@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.link-block-row {

    padding: 0 !important;

    &__inner {
        max-width: 100% !important;
        width: 100% !important;
        padding: 0 !important;
    }

}