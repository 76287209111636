@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.column-carousel {

    padding: rem($print-spacing) 0 !important;

    &__right,
    &__right-inner,
    &__wrapper,
    &__left,
    &__inner {
        position: relative !important;
        margin: 0 !important;
        width: 100% !important;
        padding: 0 !important;
        display: block !important;
        background-color: transparent !important;
    }

    &__item {

        display: block !important;
        width: 100% !important;
        margin: rem(30) 0 0 0 !important;
        padding: 0 !important;

        a {
            display: block !important;
        }

        &-img {
            display: none !important;
        }
    }

    .carousel-wrapper__container {
        max-width: 100% !important;
    }

}