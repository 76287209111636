@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.icon-block-row {

    padding: rem($print-spacing) 0 !important;
    display: block !important;

    &__wrapper {
        background-color: transparent !important;
        display: block !important;
        padding: 0 !important;
    }

    &__inner {
        padding: 0 !important;
        display: block !important;
        max-width: 100% !important;
    }
    
    &__item {
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

}
