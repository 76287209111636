@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.feature-content {
    
    opacity: 1 !important;
    transform: translateY(0) !important;

    &__text {
        ul li{
            &:before {
                background-color: $black !important;
            }
        }
    }

}