@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.content-banner {

    padding: rem($print-spacing) 0 !important;
    display: block !important;
    background-color: transparent !important;

    &__inner {
        padding: 0 !important;
    }

    h1 {
        padding: 0 0 rem(20) 0 !important;
        color: $black !important;
    }

    h2,
    h3 {
        color: $black !important;
        padding: 0 0 rem(20) 0 !important;
        margin: 0 !important;
        width: 100% !important;
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        max-width: 100% !important;
    }

}