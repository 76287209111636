@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.icon-block {

    margin: rem(30) 0 0 0 !important;
    
    &__icon {
        display: none !important;
    }

    &__content {
        
        margin: 0 !important;

        p {
            color: $black !important;
        }

    }

}