@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.section-intro {
    
    padding: 0 0 rem(20) 0 !important;
    text-align: left !important;
    display: block !important;
    width: 100% !important;

    &__inner {
        padding: 0 !important;
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    &__wrapper {
        background-color: transparent !important;
    }

}