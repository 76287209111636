@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.home-banner {

    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;

    h1 {
        color: $black !important;
        max-width: 100% !important;
        transform: translate(0, 0) !important;
        opacity: 1 !important;
        visibility: visible !important;
        height: auto !important;
    }

    &__scroll,
    &__pause-play {
        display: none !important;
    }

    &__item {
        max-width: 100% !important;
        padding: rem($print-spacing) 0 !important;
        opacity: 1 !important;
        visibility: visible !important;
        transform: translate(0, 0) !important;
        display: block !important;
        position: relative !important;
        left: 0 !important;
        height: auto !important;
        top: 0 !important;
    }

}