@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.card-wysiwyg {

    &__inner {
        padding: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }

}